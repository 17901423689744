<template>
  <div class="progress-bar">
    <div :style="barStyle" class="progress"/>
    <div v-if="progressAmountSecondary"
         :style="`width: ${barSecondaryStyleWidth}; z-index: ${barSecondaryIndex}`"
         class="progress-secondary"/>
  </div>
</template>

<script>
import {computed} from "@vue/composition-api";

export default {
  name: "ProgressBar",
  props: {
    progressAmount: {
      type: Number,
      required: true
    },
    progressAmountSecondary: {
      type: Number,
      required: false,
      default: null
    },
  },
  setup(props) {
    const width = (amount) => amount > 100 ? 100 : amount;
    const barStyle = computed(() => {
      return `width: ${width(props.progressAmount)}%;`;
    })

    const barSecondaryStyleWidth = computed(() => {
      return `${width(props.progressAmountSecondary)}%;`;
    })

    const barSecondaryIndex = computed(() => {
      // If the secondary progress is bigger, the secondary bar should be beneath the main progress bar.
      return props.progressAmount > props.progressAmountSecondary ? '3' : '1';
    })


    return {
      barStyle,
      barSecondaryStyleWidth,
      barSecondaryIndex
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";
@import "../../assets/css/base.variables";

.progress-bar {
  background-color: var(--gray-main);
  border-radius: rem(8);
  height: rem(16);
  margin-top: rem(31);
  overflow: hidden;
  position: relative;
  width: 200%;

  .progress {
    @include position-elevation(absolute, $top: 0, $right: unset, $bottom: 0, $left: 0, $elevation: 2);
    background-color: var(--green-light);
    transition: width 0.5s ease-in-out;
  }

  .progress-secondary {
    @include position-elevation(absolute, $top: 0, $right: unset, $bottom: 0, $left: 0, $elevation: 1);
    background-color: var(--green-dark);
    transition: width 1s ease-in-out;
  }
}

// Custom
.progress-order-card {
  border-radius: rem(5);
  height: rem(10);
  margin-top: 0;
  width: 100%;
}

.small-bar {
  height: rem(8);
  margin-right: rem(40);
  margin-top: 0;
  width: 35%;
}
</style>
