<template>
  <div v-if="projectDetails" class="order-overview-wrapper">
    <!-- Top - Name and hour information -->
    <div class="content-top">
      <!-- Name -->
      <TitleWithInfo @edit="toggleEditModal" :title="projectDetails.name" class="project-name"/>

      <!-- Hour information -->
      <HourInfo has-math-element
                left-text="Total available hours"
                middle-text="Total spent hours"
                right-text="Remaining hours"
                :left-stat="projectDetails.totalAvailableHoursRounded"
                :middle-stat="projectDetails.totalSpentHoursRounded"
                :right-stat="projectDetails.remainingHoursRounded"
                class="hour-indications"/>
    </div>

    <!-- Divider line -->
    <div class="divider-line"></div>

    <!-- Bottom - Logs -->
    <div class="content-bottom">
      <!-- Create new order button -->
      <DButton v-if="projectDetails.hasOrders"
               @click.native="toggleCreateOrderModal"
               text="Create new order"
               :icon="require('../../assets/icons/svg/ic_add_white.svg')"
               type="button"
               class="button-main button-create"/>

      <!-- Jira import -->
      <div class="jira">
        <JiraImport @click.native="redirectToUnassigned()"/>
        <ProcessedHours/>
      </div>

      <!-- NO existing orders -->
      <NoOrders v-if="!projectDetails.hasOrders" @create="toggleCreateOrderModal" class="no-order-indication"/>

      <!-- Logs -->
      <!-- Ongoing -->
      <div v-if="projectDetails.ongoingOrders.length" class="order-section section-ongoing">
        <SectionTitleWithIcon :icon="require('../../assets/icons/svg/ic_ongoing.svg')"
                              title="Ongoing"
                              class="order-section-title ongoing-orders-title"/>
        <OrderCard v-for="order in projectDetails.ongoingOrders"
                   :key="order.id"
                   :chip-text="order.orderType.type"
                   :chip-class="order.orderType.color"
                   :title="order.title"
                   :progress-data="order.progressData"
                   :non-billable="!order.billable"
                   @click.native="navigateToOrderDetails(order)"
                   class="order"/>
      </div>

      <!-- Completed -->
      <div v-if="projectDetails.completedOrders.length" class="order-section section-completed">
        <SectionTitleWithIcon :icon="require('../../assets/icons/svg/ic_check_success.svg')"
                              title="Completed"
                              class="order-section-title"/>
        <OrderCard v-for="order in projectDetails.completedOrders"
                   :key="order.id"
                   :completed="!!order.completed"
                   :chip-text="order.orderType.type"
                   :chip-class="order.orderType.color"
                   :title="order.title"
                   :progress-data="order.progressData"
                   :non-billable="!order.billable"
                   @click.native="navigateToOrderDetails(order)"
                   class="order"/>
      </div>
    </div>

    <!-- Create new order modal -->
    <CreateOrderModal v-if="activeModal === MODAL_NAMES.CREATE_ORDER"
                      @order-created="() => getProjectDetails(projectId)"/>

    <!-- Edit project modal -->
    <EditProjectModal v-if="activeModal === MODAL_NAMES.EDIT_PROJECT"
                      @initialize-edit="toggleEditModal"
                      @edited="() => getProjectDetails(projectId)"
                      @deleted="onProjectDeletion"
                      :project="projectDetails"/>
  </div>
</template>

<script>
import HourStatistic from "@/components/partials/hours/HourStatistic";
import OrderCard from "@/components/partials/order/OrderCard";
import DButton from "@/components/elements/DButton";
import JiraImport from "@/components/partials/order/JiraImport";
import NoOrders from "@/components/partials/order/NoOrders";
import CreateOrderModal from "@/components/partials/order/CreateOrderModal";
import {MODAL_CONTENT} from "@/models/ModalContent";
import HourInfo from "../../components/partials/HourInfo";
import SectionTitleWithIcon from "@/components/elements/SectionTitleWithIcon";
import {computed} from "@vue/composition-api";
import Toast from "@/components/elements/Toast";
import {ROUTE_NAMES_ORDER} from "@/router/modules/order";
import {ROUTE_NAMES_MANAGE_LOGS} from "@/router/modules/manageLogs";
import TitleWithInfo from "../../components/elements/TitleWithInfo";
import EditProjectModal from "@/components/partials/createProject/EditProjectModal";
import {ERR_TYPE} from "@/utils/handlers/ApiErrorMsgHandler";
import {ROUTE_NAMES_PROJECT_OVERVIEW} from "@/router/modules/projectOverview";
import getProject from "../../composables/projects/getProject";
import {getModalHelpers} from "../../composables/modalHelper";
import {MODAL_NAMES} from "../../models/ModalContent";
import getOrderHelpers from "../../composables/order/getOrderHelpers";
import ProcessedHours from "@/components/partials/processedOrder/ProcessedHours";
import {onUnmounted, watch} from "@vue/composition-api";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";
import {RESET_SELECTED_PROJECT} from "@/store/project/mutations";

export default {
  name: "OrderOverview",
  components: {
    ProcessedHours,
    EditProjectModal,
    TitleWithInfo,
    Toast,
    SectionTitleWithIcon,
    HourInfo,
    CreateOrderModal,
    NoOrders,
    JiraImport,
    DButton,
    OrderCard,
    HourStatistic,
  },
  setup(props, {root}) {
    const store = root.$store;
    const router = root.$router;
    const route = root.$route;

    const apiError = computed(() => store.getters.getApiError);
    watch(apiError, (newValue) => {
      if (newValue === ERR_TYPE.CONFLICT_ORDER_WITH_LOGS_ASSIGNED_TO_JIRA_PROJECT) {
        ToastHandler.addNew(TOAST_CONTENTS.PROJECT_DELETE_UNALLOWED);
      } else if (newValue === ERR_TYPE.PROJECT_NOT_FOUND) {
        ToastHandler.addNew(TOAST_CONTENTS.PROJECT_NOT_FOUND);
        router.push({name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW});
      }
    })

    // Get URL params.
    const projectId = root.$route.params.projectId;

    /** Project details **/
    const {getProjectDetails, projectDetails} = getProject();
    getProjectDetails(projectId);

    /** Edit project **/
    function toggleEditModal() {
      setModal(MODAL_CONTENT.EDIT_PROJECT(null, closeModal));
    }

    /** Delete project **/
    // We need a listener cause the edit modal closes after the delete warning modal pops up.
    // In result, the callback passed in the modal content would not be fired.
    // TODO: Improve the popups logic.
    document.addEventListener("project-deleted", (event) => {
      const {name} = event.detail;
      onProjectDeletion(name);
    });

    function onProjectDeletion() {
      // Close the modal.
      closeModal();

      // Navigate back to the projects overview.
      router.push({name: ROUTE_NAMES_PROJECT_OVERVIEW.OVERVIEW});
    }

    /** Create order **/
    function toggleCreateOrderModal() {
      setModal(MODAL_CONTENT.CREATE_ORDER());
    }

    /** Order types **/
    const {getOrderTypes} = getOrderHelpers();
    getOrderTypes();

    /** Order details **/
    function navigateToOrderDetails(order) {
      router.push({name: ROUTE_NAMES_ORDER.ORDER_DETAILS, params: {projectId, orderId: order.id},});
    }

    /** Unassigned logs **/
    function redirectToUnassigned() {
      router.push({name: ROUTE_NAMES_MANAGE_LOGS.UNASSIGNED_LOGS, params: {projectId},});
    }

    /** Modal **/
    const {activeModal, setModal, closeModal} = getModalHelpers();


    onUnmounted(() => {
      store.commit(RESET_SELECTED_PROJECT);
    });

    return {
      ERR_TYPE,
      apiError,
      MODAL_NAMES,

      /** Project details **/
      projectId,
      getProjectDetails,
      projectDetails,

      /** Project edit **/
      toggleEditModal,

      /** Delete project **/
      onProjectDeletion,

      /** Create order **/
      toggleCreateOrderModal,

      /** Order details **/
      navigateToOrderDetails,

      /** Unassigned logs **/
      redirectToUnassigned,

      /** Modal **/
      activeModal,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

// General - Page
.order-overview-wrapper {
  //padding: rem(40) rem(30);
}

// Top
.content-top {
  display: flex;
  flex-wrap: wrap;

  .project-name {
    flex-basis: 40%;
    text-align: left;

    .name {
      font-size: rem(42);
      font-weight: bold;
      line-height: rem(52);
      margin-right: rem(16);
    }
  }
}

// Bottom
.content-bottom {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

// Create order
.button-create {
  @include position(absolute, $top: rem(17), $right: 0);
  height: rem(52);
  width: rem(233);
}

// Jira
.jira {
  flex-basis: rem(288);
  height: 100%;
}

// Order
.order-section {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-basis: calc(100% - #{rem(288)} - #{rem(20)});

  .order {
    margin-bottom: rem(33);

    &:not(:last-child) {
      margin-right: rem(33);
    }
  }

  &-title {
    flex-basis: 100%;
    margin-bottom: rem(25);
    text-align: left;

    &.ongoing-orders-title {
      margin-bottom: 0;
      padding-bottom: rem(33);
      padding-top: rem(33);
    }
  }
}

.section-completed {
  margin-left: auto;
  margin-top: rem(80);
}

.no-order-indication {
  align-items: center;
  flex-basis: calc(100% - #{rem(288)} - #{rem(20)});
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
