<template>
  <div class="no-orders">
    <p class="main-text">Start managing your logs</p>
    <p class="text">Create an order to which you can assign logs from the Jira import.</p>

    <DButton @click.native="$emit('create')"
             text="Create order"
             :icon="require('../../../assets/icons/svg/ic_add_white.svg')"
             type="button"
             class="button-main button-create"/>
  </div>
</template>

<script>
import DButton from "@/components/elements/DButton";

export default {
  name: "NoOrders",
  components: {
    DButton
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.no-orders {
  .main-text {
    @extend .mont-bold;
    font-size: rem(24);
    line-height: rem(29);
    margin-bottom: rem(32);
  }

  .text {
    color: var(--gray-light-03);
    font-size: rem(20);
    line-height: rem(30);
    margin-bottom: rem(48);
  }

  .button-create {
    align-items: center;
    display: flex;
    height: rem(52);
    justify-content: center;
    width: rem(187);
  }
}
</style>
