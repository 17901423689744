<template>
  <div class="date-tag">
    <img src="../../assets/icons/svg/ic_date_active.svg" class="tag-icon" alt=""/>
    <span class="tag-text">{{text}}</span>
  </div>
</template>

<script>
export default {
  name: "DateTag",
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.date-tag {
  align-items: center;
  display: flex;

  .tag-icon {
    height: rem(24);
    width: rem(24);
  }

  .tag-text {
    font-size: rem(14);
    font-weight: bold;
    line-height: rem(19);
    margin-left: rem(8);
  }
}
</style>
