<template>
  <div class="jira-import">

    <!-- Title -->
    <div class="title-wrapper">
      <img src="../../../assets/icons/svg/ic_import_primary.svg" class="icon" alt="arrow"/>
      <span class="title">Jira import</span>
    </div>

    <!-- Manage logs -->
    <OrderCard :has-progress="false"
               :chip-icon="require('../../../assets/icons/svg/ic_alert.svg')"
               chip-text="to assign"
               title="Manage Jira logs"
               chip-class="yellow">
      <template #content>
        <div class="logs-box">
          <div class="logs">
            <p class="log-title">Logs</p>
            <p class="amount">{{ projectDetails.totalUnassignedLogs }}</p>
          </div>
          <div class="logs">
            <p class="log-title">Hours</p>
            <p class="amount">{{ projectDetails.totalUnassignedHours }}</p>
          </div>
        </div>
      </template>
    </OrderCard>
  </div>
</template>

<script>
import OrderCard from "@/components/partials/order/OrderCard";
import {computed} from "@vue/composition-api";

export default {
  name: "JiraImport",
  components: {
    OrderCard
  },
  setup(props, {root}) {
    const store = root.$store;

    // Get the selected project.
    const projectDetails = computed(() => store.getters.getSelectedProject);

    return {
      projectDetails
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.jira-import {
  background-color: var(--gray-main);
  border-radius: rem(10);
  margin-right: rem(20);
  padding: rem(20) rem(20) rem(27) rem(20);
}

// Title
.title-wrapper {
  background-color: white;
  border-radius: rem(10);
  margin-bottom: rem(21);
  padding: rem(12) rem(16);
  width: rem(204);

  .icon {
    height: rem(24);
    margin-right: rem(10);
    vertical-align: sub;
    width: rem(24);
  }

  .title {
    @extend .mont-bold;
    font-size: rem(24);
    line-height: rem(29);
  }
}

// Card - Manage logs
.logs-box {
  display: flex;
  margin-top: rem(14);

  .logs {
    flex-basis: 50%;

    &:first-child {
      margin-right: rem(16);
    }

    .log-title {
      @extend .mont-bold;
      color: var(--gray-light-03);
      font-size: rem(12);
      line-height: rem(15);
      margin-bottom: rem(3);
      text-align: left;
    }

    .amount {
      @extend .mont-bold;
      background-color: var(--gray-main);
      border-radius: rem(3);
      font-size: rem(24);
      line-height: rem(29);
    }
  }
}
</style>
