<template>
  <div class="title-with-info">

    <div class="title-wrapper">
      <h1 class="main-title">{{ title }}</h1>
      <div v-if="editable" @click="$emit('edit')" class="edit-button-small">
        <img src="../../assets/icons/svg/ic_edit.svg" class="icon" alt="edit"/>
      </div>
    </div>

    <div v-if="chipOptions" class="chips-wrapper">
      <Chip v-for="option in chipOptions"
            :key="option.id"
            :text="option.text"
            :custom-class="option.customClass"
            :icon="option.icon"
            class="page-chip"/>
    </div>

    <DateTag v-if="dateTag" :text="dateTag" class="since-date"/>
  </div>
</template>

<script>
import Chip from "@/components/elements/Chip";
import DateTag from "@/components/elements/DateTag";

export default {
  name: "TitleWithInfo",
  components: {
    DateTag,
    Chip
  },
  props: {
    title: {
      type: String,
      required: true
    },
    chipOptions: {
      type: Array,
      required: false,
    },
    dateTag: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['edit']
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.title-with-info {

  .title-wrapper {
    align-items: center;
    display: flex;
    flex-basis: 100%;
    flex-shrink: 0;
    margin-bottom: rem(12);

    .main-title {
      margin-right: rem(20);
    }
  }

  .chips-wrapper {
    display: flex;
    grid-gap: 8px;
  }

  .page-chip {
    flex-grow: 0;
  }

  .since-date {
    margin-left: rem(16);
  }
}
</style>
