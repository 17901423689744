<template>
  <div class="hour-info">
    <HourStatistic :title="leftText" :amount="leftStat"/>

    <!-- Dividers -->
    <div v-if="hasVerticalDevider" class="divider-line-vertical"></div>
    <p v-if="hasMathElement" class="math-element">-</p>

    <HourStatistic :title="middleText" :amount="middleStat"/>

    <!-- Dividers -->
    <p v-if="hasMathElement" class="math-element">=</p>
    <div v-if="hasVerticalDevider" class="divider-line-vertical"></div>

    <HourStatistic :title="rightText" :amount="rightStat"/>

    <!-- Progress bar -->
    <ProgressBar :progress-amount="progressAmount"/>
  </div>
</template>

<script>
import HourStatistic from "./hours/HourStatistic";
import ProgressBar from "@/components/elements/ProgressBar";
import {computed} from "@vue/composition-api";

export default {
  name: "HourInfo",
  components: {
    ProgressBar,
    HourStatistic
  },
  props: {
    hasMathElement: {
      type: Boolean,
      required: false,
      default: false
    },
    hasVerticalDevider: {
      type: Boolean,
      required: false,
      default: false
    },

    // Texts
    leftText: {
      type: String,
      required: false,
    },
    middleText: {
      type: String,
      required: false,
    },
    rightText: {
      type: String,
      required: false,
    },

    // Stats
    leftStat: {
      type: [Number,String],
      required: true
    },
    middleStat: {
      type: [Number,String],
      required: true
    },
    rightStat: {
      type: [Number,String],
      required: true
    },
  },
  setup(props) {
    /** Calculate progress statistics **/
    const progressAmount = computed(() => (props.middleStat / props.leftStat) * 100);

    return {
      /** Calculate progress statistics **/
      progressAmount
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.mixins";

.hour-info {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .math-element {
    color: var(--gray-light-03);
    font-size: rem(24);
    line-height: rem(29);
  }
}
</style>
