<template>
  <div :class="{'completed': completed}" class="order-card">

    <div class="top-wrapper">

      <!-- Title -->
      <p class="title">{{ title }}</p>

      <img src="../../../assets/icons/svg/ic_next_active_dark_grey.svg" class="icon" alt="arrow"/>
    </div>

    <!-- Type of order -->
    <div class="chips-wrapper">
      <Chip :text="chipText" :custom-class="chipClass" :icon="chipIcon" class="order-type"/>
      <Chip v-if="nonBillable" :text="nonBillableChip.text" :custom-class="nonBillableChip.customClass" :icon="null" class="order-type"/>
    </div>

    <!-- Progress -->
    <div v-if="progressData !== null" class="progress-wrapper">
      <p class="progress-info">{{ progressData.spentHours }}/{{ progressData.availableHours }} Hours
        ({{ progressData.percentage }}%)</p>

      <!-- Progress bar -->
      <ProgressBar :progress-amount="progressData.percentage" class="progress-order-card"/>
    </div>

    <!-- TODO: Custom content -->
    <slot name="content"/>
  </div>
</template>

<script>
import Chip from "@/components/elements/Chip";
import ProgressBar from "@/components/elements/ProgressBar";
import ChipOption, {CHIP_STYLES} from "@/models/ChipOption";

export default {
  name: "OrderCard",
  components: {
    ProgressBar,
    Chip
  },
  props: {
    // Texts/icons
    title: {
      type: String,
      required: true
    },
    chipText: {
      type: String,
      required: true
    },
    chipClass: {
      type: String,
      required: false
    },
    chipIcon: {
      type: String,
      required: false
    },

    // Styling
    completed: {
      type: Boolean,
      required: false,
      default: false
    },
    nonBillable: {
      type: Boolean,
      required: false
    },

    // Progress
    progressData: {
      type: Object,
      required: false,
      default: null
    }
  },
  setup(props) {
    return {
      nonBillableChip: new ChipOption('Non billable', CHIP_STYLES.LIGHT_GRAY)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.order-card {
  background-color: white;
  border: rem(1) solid var(--gray-light-04);
  border-radius: rem(10);
  box-shadow: rem(2) rem(4) rem(8) 0 rgba(70, 70, 70, 0.1);
  padding: rem(20) rem(14);
  transition: 0.1s all ease-in-out;
  width: rem(248);

  // Hover transition
  @include hover-active-pointer {
    box-shadow: none;
    transform: scale(1.01);
  }
}

.top-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(8);

  .title {
    @extend .mont-bold;
    font-size: rem(20);
    letter-spacing: 0;
    line-height: rem(24);
    text-align: left;

    // Add the ellipsis if the order name is too long.
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .icon {
    height: rem(24);
    width: rem(24);
  }
}

// Chip
.chips-wrapper {
  display: flex;
  grid-gap: rem(5);
}
.order-type {
  width: rem(89);
}

// Progress
.progress-wrapper {
  margin-top: rem(32);
  text-align: left;

  .progress-info {
    @extend .mont-bold;
    color: var(--gray-light-03);
    font-size: rem(12);
    line-height: rem(22);
    margin-bottom: rem(3);
  }
}

//--------------------------------------------------------------------------------------
// Card styles
//--------------------------------------------------------------------------------------
.completed {
  border-color: var(--green-light);
}
</style>
