<template>
  <div @click="navigateToProcessedHours()" class="processed-hours">
    <div class="title-wrapper">
      <span class="title">Processed</span>
      <img src="../../../assets/icons/svg/ic_next_active_dark_grey.svg" class="arrow-icon" alt="arrow"/>
    </div>
    <p class="extra-info">These hours won’t be included in the total hours calculation.</p>
<!--    <p class="hours">Hours not available yet</p>-->
    <p class="hours">{{ projectDetails.totalProcessedHours }} Hours</p>
  </div>
</template>

<script>
import {computed} from "@vue/composition-api";
import {ROUTE_NAMES_ORDER} from "@/router/modules/order";

export default {
  name: "ProcessedHours",
  setup(props, {root}) {
    const store = root.$store;
    const router = root.$router;

    // Get the selected project.
    const projectDetails = computed(() => store.getters.getSelectedProject);

    function navigateToProcessedHours() {
      router.push({name: ROUTE_NAMES_ORDER.ORDER_PROCESSED});
    }

    return {
      projectDetails,
      navigateToProcessedHours
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

.processed-hours {
  @include hover-active-pointer;
  background-color: var(--gray-main);
  border-radius: rem(10);
  margin-right: rem(20);
  margin-top: rem(16);
  padding: rem(20) rem(16) rem(27) rem(16);
  text-align: left;

  .hours {
    color: var(--gray-light-03);
    font-size: rem(12);
    font-weight: var(--montserrat-weight-bold);
    line-height: rem(22);
  }
}

// Title
.title-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: rem(10);

  .arrow-icon {
    height: rem(24);
    margin-left: auto;
    vertical-align: sub;
    width: rem(24);
  }

  .title {
    font-size: rem(20);
    font-weight: var(--montserrat-weight-bold);
    line-height: rem(24);
  }
}

// Extra information
.extra-info {
  color: var(--gray-light-03);
  font-size: rem(12);
  line-height: rem(15);
  margin-bottom: rem(17);
  padding-right: rem(24);
}
</style>